<template>
  <div id="wishlist-view">
    
    <section class="bg-light">
      <main>
        
      </main>
      <div class="col-md-12">
        <div class="findgft">
          <span class="badge pull-right " style="font-size:15px;background-color:#ddd;">
             <h6>These are gift on your <br> Friends & Family's Wishlist</h6>
          </span>
         <router-link to='/wishlist' class="sm_megamenu_head sm_megamenu_drop "
                            id="sm_megamenu_286" href="">  <h1 class="display-4">Friends & Family Wishlist</h1></router-link>
                            
                           
        </div>
        <div class="d-flex justify-content-center">
          <img src="@/assets/site/images/loader.gif" v-if="loader===true" class="img-fluid  " alt />
          <!-- <img src="@/assets/site/images/account.svg" width="20" height="20" class="avatar-img-gift"  alt="">  -->
        </div>
      </div>
      <div class="container-fluid pt-5">
 

      </div>
      <!-- <div class="container-fluid">
         <toggle-button :value="wishlist_status== 'Private' ? true: false" @change="changeStatus(wishlist_status)"     color="#c8730e"  />
        <hr />
      </div> -->
      <p v-if="imageshow">
       <span class="d-flex justify-content-center"><img class="img-fluid text-center" src="@/assets/site/images/Group1.svg" alt=""></span>   
        </p>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10">
            <ul>
              <li v-for="contact in contacts" class="sm_megamenu_title ml-4 my-3" @click="showOccasionList(contact)">
                <!-- <router-link v-for="contact in contacts" class="sm_megamenu_title ml-4 my-3" to="occasion_wishlist"> -->
                  <h3>
                    <span class="col-md-1">
                      <img src="@/assets/site/images/account.svg" width="20" height="20" class="avatar-img-gift"  alt=""> 
                    </span>  
                    <span class="col-md-9">
                      {{contact.name}}
                    </span> 
                    
                  
                  </h3>
                <!-- </router-link> -->
                
              </li>
            </ul>
          </div>
          <div class="col-md-9">
            <friends-wishlist v-if="show" v-bind:wishlistproducts="wishlistproducts"></friends-wishlist>
          </div>
        </div>
      </div>


      <div class="container">
        <hr />
      </div>


    </section>

    <!-- End Footer -->

  </div>
</template>
<script>
    import FriendsWishlist from '@/views/site/user/FriendswishlistView'
 

    export default {
        name: "FriendslistView",
        data() {
            return {
                loader: false,
                errors: [],
                pages: [],
                contacts: [],
                wishlistproducts: [],
                imageshow: false,
                wishlist_status:null,
                show: false,
            };
        },
        components: { FriendsWishlist },
        created() {
           
            this.getWishlistContacts();

        },
        methods: {
            getWishlistContacts() {
                this.errors = [];
                this.loader = true;
                var token = localStorage.getItem("userData");
                var userdata_array = JSON.parse(token);
                var user_id = userdata_array.user.id;
                //  var api_token = userdata_array.token;
                let method_type = "";
                let fetch_url = "";
                method_type = "post";
                fetch_url = process.env.VUE_APP_URL + "customer/wishlist/user_contacts";
                fetch(fetch_url, {
                        method: method_type,
                        headers: {
                            "content-type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",

                        },
                        body: JSON.stringify({

                            user_id: user_id,
                        }),
                    })
                    .then(res => res.json())
                    .then(data => {
                        this.loader = false;
                        this.errors = [];
                        this.contacts = data.contacts;
                        if(data.contacts.length == 0){
                           this.imageshow = true;
                        }
                        // this.wishlist_status=data.wishlist_status;
                        // if (this.wishlistproducts.length == 0) {
                        //     this.imageshow = true;
                        // }
                    })

            },
          showWishlist(contact){
            // this.$router.replace({
            //     name: 'FriendswishlistView',
            //     params: {
            //       wishlist:contact.wishlist,
            //     }
            // })
            this.show = true;
            this.wishlistproducts = contact.wishlist;
          } , 
          showOccasionList(contact){
            var page_name = this.$route.meta.title;
            var path = this.$route.path;
            this.$router.replace({
                name: 'Occasionwishlist',
                params: {
                  occasion_list:contact.occasion_lists,
                  pet_wishlist: contact.pet_wishlist,
                  user_contact_id:contact.id,
                  page_name: page_name,
                  path: path,
                }
            })
            // this.show = true;
            // this.wishlistproducts = contact.wishlist;
          } ,  
                    
        }
    }
</script>